import api, { ResponseProps } from "../apiConfig";

interface OpenChatAsBookerPayload {
  hoster_room_id: string;
}
interface OpenChatAsHosterPayload {
  booker_room_id: string;
}

interface ListMessagesPayload {
  chat_id: string;
}

interface SendMessagePayload {
  chat_id: string;
  message: string;
}
interface SendMailPayload {
  chat_id: string;
}

interface OpenChatresponseData extends ResponseProps {
  data: {
    chat_id: string;
  };
}

interface ListChatresponseData extends ResponseProps {
  data: {
    author: string;
    avatar: string;
    message: string;
    sent_date: string;
    user_id: string;
  };
}
interface ListMessagesresponseData extends ResponseProps {
  data: {
    profile_type: string;
    chating_with: { id: string; name: string };
    messages: {
      author: string;
      avatar: string;
      message: string;
      sent_date: string;
      user_id: string;
    }[];
    room_id: string;
    room_name: string;
  };
}

const chat = {
  openChatAsBooker: async (
    data: OpenChatAsBookerPayload
  ): Promise<OpenChatresponseData> =>
    api.post({ url: `v1/chat/booker`, body: data }),

  openChatAsHoster: async (
    data: OpenChatAsHosterPayload
  ): Promise<OpenChatresponseData> =>
    api.post({ url: `v1/chat/hoster`, body: data }),

  listHosterChats: async (): Promise<ListChatresponseData> =>
    api.get({ url: `/v1/chat/hoster` }),

  listBookerChats: async (): Promise<ListChatresponseData> =>
    api.get({ url: `/v1/chat/booker` }),

  listMessages: async (
    data: ListMessagesPayload
  ): Promise<ListMessagesresponseData> =>
    api.post({ url: `/v1/chat/list`, body: data }),

  sendMessage: async (
    data: SendMessagePayload
  ): Promise<ListChatresponseData> =>
    api.post({ url: `/v1/chat/send  `, body: data }),

  sendMail: async (data: SendMailPayload): Promise<ResponseProps> =>
    api.post({ url: `/v1/chat/scheduler  `, body: data }),
};

export default chat;
