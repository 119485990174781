import { memo, useState } from "react";
import BackArrow from "@lc/ui/icons/BackArrow";
import ForwardArrowIcon from "@lc/ui/icons/ForwardArrow";

import Drawer from "@lc/ui/components/Drawer";
import Text from "@lc/ui/components/Text";
import helperData from "@lc/lib/data/data-helper";
import Button from "@lc/ui/components/Button";

import styles from "./helper.module.scss";
type Props = {
  item: { title: string; resume: string; description: string };
};
const HelperDetail = ({ item }: Props) => {
  const [openHelp, setOpenHelp] = useState(false);

  return (
    <div>
      <Drawer
        direction="RTL"
        open={openHelp}
        onClose={() => setOpenHelp(false)}
      >
        <div className={styles.wrapper}>
          <Text
            styleAs="body1"
            styleAsOnMd="h3"
            color="green"
            className={styles.title}
          >
            Ajuda Recomendada
          </Text>
          <Button
            naked
            onClick={() => {
              setOpenHelp(false);
            }}
          >
            <Text className={styles.knowMore}>
              <BackArrow />
              Voltar
            </Text>
          </Button>
          <Text
            styleAs="body2"
            styleAsOnMd="h4"
            className={styles.resume}
            element="div"
            dangerouslySetInnerHTML={{
              __html: `<b>${item.title}</b>`,
            }}
          />
          <Text
            styleAs="body2"
            styleAsOnMd="h5"
            className={styles.resume}
            element="div"
            dangerouslySetInnerHTML={{
              __html: item.description,
            }}
          />
        </div>
      </Drawer>

      <Text styleAs="body1" styleAsOnMd="h4" className={styles.subtitle}>
        {item.title}
      </Text>
      <Text
        styleAs="body2"
        styleAsOnMd="h5"
        className={styles.resume}
        element="div"
        dangerouslySetInnerHTML={{
          __html: item.resume,
        }}
      />
      <Button
        naked
        onClick={() => {
          setOpenHelp(true);
        }}
      >
        <Text className={styles.knowMore}>
          Saiba mais
          <ForwardArrowIcon />
        </Text>
      </Button>
    </div>
  );
};

const Helper = () => (
  <div className={styles.wrapper}>
    <Text
      styleAs="body1"
      styleAsOnMd="h3"
      color="green"
      className={styles.title}
    >
      Ajuda Recomendada
    </Text>

    <div>
      {helperData.faq.map((item) => (
        <HelperDetail key={item.title} item={item} />
      ))}
    </div>
  </div>
);

export default memo(Helper);
