import { useEffect, useCallback } from "react";
import { useRouter } from "next/router";

import api from "@lc/lib/api";
import useModal from "@lc/lib/hooks/useModal";

import Image from "@lc/ui/components/Image";
import Text from "@lc/ui/components/Text";
import Button from "@lc/ui/components/Button";

import styles from "./welcome.module.scss";

const ValidationsModal = () => {
  const { query } = useRouter();

  const resendEmail = useCallback(async (cb) => {
    await api.email.resendEmail({ redirect_to: "/" });
    cb();
  }, []);

  const { openModal: openWelcomeModal, Modal: WelcomeModal } = useModal({
    content: ({ innerCloseModal }) => (
      <div className={styles.welcomeModal}>
        <div className={styles.logo}>
          <Image
            src="images/LetClinic_subtitle.png"
            alt="letclinic logo"
            width="146px"
            height="120px"
          />
        </div>
        <div>
          <Text>
            <b>Tudo certo!</b>
          </Text>
          <Text>É um prazer ter você com a gente!</Text>
          <Text styleAs="body2">
            Conheça nossa plataforma, que oferece mobilidade e otimiza recursos
          </Text>
          <Button
            className={styles.explorerBtn}
            fullWidth
            sizeOnSm="lg"
            label="Comece a explorar"
            onClick={innerCloseModal}
          />
        </div>
      </div>
    ),
  });

  const { openModal: openExpiredEmailModal, Modal: ExpiredEmailModal } =
    useModal({
      content: ({ innerCloseModal }) => (
        <div className={styles.welcomeModal}>
          <div>
            <Text>
              <b>Seu link expirou!</b>
            </Text>
            <Text>
              Clique abaixo e reenviamos um novo link de confirmação no seu
              e-mail. Caso não o encontre na Caixa de Entrada do e-mail
              informado, pedimos que também verifique na sessão de Spam. Em
              alguns casos, o e-mail pode ser direcionado para esse local.
            </Text>
            <Text>
              <b>Importante: o link expira em 24 horas.</b>
            </Text>

            <Button
              className={styles.explorerBtn}
              fullWidth
              sizeOnSm="lg"
              label="Reenviar e-mail"
              onClick={() => {
                resendEmail(innerCloseModal);
              }}
            />
          </div>
        </div>
      ),
    });

  const activeEmail = useCallback(
    async (token) => {
      const { hasError } = await api.email.activeEmail(token);
      if (!hasError) {
        openWelcomeModal();
      } else {
        openExpiredEmailModal();
      }
    },
    [openWelcomeModal, openExpiredEmailModal]
  );

  useEffect(() => {
    const canExplore = JSON.parse(
      sessionStorage.getItem("canExplore") || "false"
    );
    if (canExplore) {
      openWelcomeModal();
      sessionStorage.removeItem("canExplore");
    }
    if (query?.token && query?.t === "email") {
      console.log("query", query);
      activeEmail(query.token);
    }
  }, [openWelcomeModal, activeEmail, query]);

  return (
    <>
      {WelcomeModal}
      {ExpiredEmailModal}
    </>
  );
};

export default ValidationsModal;
