// from
// https://github.com/alibaba/hooks/blob/master/packages/hooks/src/useResponsive/index.ts
import { useEffect, useState } from "react";

type Subscriber = () => void;

const subscribers = new Set<Subscriber>();

type ResponsiveConfig = Record<string, number>;
type ResponsiveInfo = Record<string, boolean>;

let info: ResponsiveInfo = {};

let responsiveConfig: ResponsiveConfig = {
  xs: 0,
  sm: 600,
  md: 960,
  lg: 1200,
  xl: 1536,
};

let listening = false;

function calculate(): void {
  const width = window.innerWidth;
  const newInfo = {} as ResponsiveInfo;
  let shouldUpdate = false;
  for (const key of Object.keys(responsiveConfig)) {
    newInfo[key] = width >= responsiveConfig[key];
    if (newInfo[key] !== info[key]) {
      shouldUpdate = true;
    }
  }
  if (shouldUpdate) {
    info = newInfo;
  }
}

function calculateBreakpoint(newInfo: ResponsiveInfo) {
  const { xs, sm, md, lg, xl } = newInfo;
  const breakpoint = { xs: false, sm: false, md: false, lg: false, xl: false };
  console.log("newInfo", newInfo);
  if (xl) breakpoint.xl = true;
  if (lg) breakpoint.lg = true;
  if (md) breakpoint.md = true;
  if (sm) breakpoint.sm = true;
  if (xs) breakpoint.xs = true;

  return { ...newInfo, breakpoint };
}

function handleResize(): void {
  const oldInfo = info;
  calculate();
  if (oldInfo === info) return;
  for (const subscriber of subscribers) {
    subscriber();
  }
}

export function configResponsive(config: ResponsiveConfig): void {
  responsiveConfig = config;
  if (info) calculate();
}

export function useResponsive(): ResponsiveInfo {
  const windowExists = typeof window !== "undefined";
  if (windowExists && !listening) {
    info = {};
    calculate();
    window.addEventListener("resize", handleResize);
    listening = true;
  }
  const [state, setState] = useState<ResponsiveInfo>(info);

  useEffect(() => {
    if (!windowExists) return;

    const subscriber = (): void => {
      setState(info);
    };
    subscribers.add(subscriber);
    return (): void => {
      subscribers.delete(subscriber);
      if (subscribers.size === 0) {
        window.removeEventListener("resize", handleResize);
        listening = false;
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return state;
}
