import React, { useRef, useState, FocusEvent, useEffect } from "react";
import classNames from "classnames";

import useClickOutside from "@lc/lib/hooks/useClickOutside";

import Suggestions, { OptionsType } from "./Suggestions";
import styles from "./index.module.scss";

interface Props extends React.InputHTMLAttributes<HTMLInputElement> {
  label?: string;
  options?: OptionsType[];
  optionsOffset?: number;
  onChoose?: (data: OptionsType) => void;
  closeOnClickOutside?: boolean;
  color?: "primary" | "secondary";
  qaLabel?: string;
  variant?: "outline" | "default" | "naked";
  error?: boolean;
  errorMessage?: string;
}

const Input = (props: Props): React.ReactElement => {
  const {
    options,
    optionsOffset,
    onChoose,
    onFocus,
    label = "",
    placeholder = " ",
    variant = "default",
    color = "primary",
    error = false,
    errorMessage = "",
    qaLabel,
    ...rest
  } = props;
  const inputWrapperRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);

  const hasLabel = Boolean(label);
  const ariaLabel = label;

  const [suggestionsOpen, setSuggestionsOpen] = useState(
    Boolean(options?.length)
  );

  useClickOutside(inputWrapperRef, () => setSuggestionsOpen(false));

  useEffect(() => {
    setSuggestionsOpen(Boolean(options?.length));
  }, [options]);

  return (
    <div
      ref={inputWrapperRef}
      className={classNames(styles.wrapper, {
        [styles.noLabel]: !hasLabel,
      })}
    >
      <label>
        <input
          data-qalabel={qaLabel || label}
          className={classNames(styles.input, {
            [styles.default]: variant === "default",
            [styles.outline]: variant === "outline",
            [styles.naked]: variant === "naked",
            [styles.primary]: color === "primary",
            [styles.secondary]: color === "secondary",
            [styles.error]: error,
          })}
          onFocus={(evt: FocusEvent<HTMLInputElement>): void => {
            onFocus?.(evt);
            setSuggestionsOpen(Boolean(options?.length));
          }}
          {...rest}
          ref={inputRef}
          autoComplete="off"
          placeholder={placeholder}
        />
        {hasLabel ? (
          <label
            className={classNames(styles.label, {
              [styles.primary]: color === "primary",
              [styles.secondary]: color === "secondary",
              [styles.default]: variant === "default",
              [styles.outline]: variant === "outline",
              [styles.error]: error,
            })}
          >
            {label}
          </label>
        ) : null}
      </label>
      {error ? (
        <span className={styles.errorMessage}>{errorMessage}</span>
      ) : null}

      <div
        className={classNames({
          [styles.suggestionsOpen]: suggestionsOpen,
          [styles.suggestionsClose]: !suggestionsOpen,
        })}
      >
        <Suggestions
          optionsOffset={optionsOffset}
          options={options}
          inputRef={inputRef}
          onChoose={(value) => {
            setSuggestionsOpen(false);
            onChoose?.(value);
          }}
        />
      </div>
    </div>
  );
};

export default Input;
