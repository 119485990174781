import { Component, ReactNode } from "react";

import ErrorPage from "@lc/ui/template/ErrorPage";
import logger from "@lc/lib/helpers/logger";

interface Props {
  children: ReactNode;
}

interface State {
  hasError: boolean;
}

class ErrorBoundary extends Component<Props, State> {
  public state: State = {
    hasError: false,
  };

  public static getDerivedStateFromError() {
    return { hasError: true };
  }

  public componentDidCatch(error: Error) {
    const errorString = error?.name ? `${error.name} - ${error.message}` : "";
    logger.logError(`Error boundary ${errorString}`, { error });
  }

  public render() {
    if (this.state.hasError) {
      return <ErrorPage statusCode={1} />;
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
