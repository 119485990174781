import api from "../apiConfig";
import formatDate from "../../helpers/formatDate";

const log = {
  save: (from: string, data: string) => {
    const json = {
      time: formatDate(new Date(), "YYYY/MM/DD HH:mm:ss"),
      body: `${from}: ${JSON.stringify(data)}`,
    };
    return api.put({
      url: `v1/upload/customlog`,
      body: { log: `{FE} - ${json.time} - ${json.body}` },
      extras: { baseURL: "https://api.letclinic.com/api/" },
    });
  },
};

export default log;
