import React from "react";
import classnames from "classnames";
import dynamic from "next/dynamic";

const MobileMenu = dynamic(() => import("./MobileMenu"));

import Footer from "./Footer";
import Header from "./Header";

import ValidationsModal from "../Modal/ValidationsModal";

import styles from "./index.module.scss";

const Layout = ({
  children,
  showFooter = true,
  hideSearch = false,
}: {
  children: React.ReactElement | React.ReactElement[];
  showFooter?: boolean;
  hideSearch?: boolean;
}): React.ReactElement => (
  <>
    <Header hideSearch={hideSearch} />
    <ValidationsModal />
    <main
      className={classnames(styles.main, {
        [styles.noFooter]: !showFooter,
      })}
    >
      {children}
      {showFooter ? <Footer /> : null}
      <MobileMenu />
    </main>
  </>
);

export default Layout;
