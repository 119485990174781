let link = "https://www.letclinic.com";

export default {
  faq: [
    {
      title: "O que é a Let Clinic?",
      resume:
        "Somos uma plataforma que cria valor para profissionais da saúde das mais diversas especialidades como médicos, dentistas, fisioterapeutas, psicólogos,",
      description: `
      <p>Somos uma plataforma que cria valor para profissionais da saúde das mais diversas especialidades como médicos, dentistas, fisioterapeutas, psicólogos,
      nutricionistas e fonoaudiólogos contribuindo para otimizar seus recursos, tornar rentável seu espaço ocioso e possibilitar a mobilidade do local de trabalho.</p>

      <h4><b>Na Let Clinic você pode:</b></h4>
      <p>- Compartilhar seu consultório e equipamentos com total controle e autonomia.<br /> 
      - Alugar, com flexibilidade, o espaço de outros profissionais para receber seus pacientes.<br />
      - Comprometidos em integrar tecnologia à saúde, buscamos empoderar e ser um facilitador para os profissionais da área. 
      Sempre com qualidade e a devida segurança em nossos processos e serviços.
      </p>
      <h4><b>Quem pode fazer parte?</b> </h4>
      <p>
      Qualquer profissional da saúde que esteja com seus registros profissionais e pessoais regularizados. O cadastro em nosso sistema é gratuito, assim como a publicação dos anúncios. Para saber mais sobre nossa <a href="${link}/terms/terms-of-payment" taget="_blank">Política de Preços</a>, acesse a seção <a href="${link}/terms/privacy-policy" taget="_blank">Termos e Condições de Uso e Política de Privacidade</a>.
    </p>`,
    },
    {
      title: "Como crio uma conta?",
      resume: `Acesse nossa página inicial, clique em Entrar para <a href="${link}/login" taget="_blank">Cadastrar-se</a>.`,
      description: `Acesse nossa página inicial, clique em Entrar para <a href="${link}/login" taget="_blank">Cadastrar-se</a>. O cadastro é gratuito e você pode usar seu e-mail ou sua conta no LinkedIn, Facebook ou Google. Não esqueça de preencher todas as informações antes de começar a usar nossos serviços. Todas elas passarão por nossa verificação, e caso estejam de acordo, seu acesso será liberado. `,
    },
    {
      title: "Como cadastro meu espaço?",
      resume:
        "Cadastre-se em nossa plataforma e forneça todas as informações necessárias. Seu",
      description:
        "Cadastre-se em nossa plataforma e forneça todas as informações necessárias. Seu cadastro de perfil precisa estar completamente preenchido antes de publicar o seu anúncio. Feito isso, clique na opção Quero Anunciar para iniciar o cadastro do seu espaço e adicionar todas as informações, incluindo foto, especialidade e descrição do seu consultório.",
    },
    {
      title: "Como edito as informações do meu anúncio?",
      resume: `Navegação celular: após fazer o login, no menu fixo inferior, clique em perfil e acesse a opção`,
      description: `Navegação celular: após fazer o login, no menu fixo inferior, clique em perfil e acesse a opção <a href="${link}/places" taget="_blank">Meus Anúncios</a>. Navegação desktop: após fazer o login no menu superior à direita, clique em sua foto e acesse a opção <a href="${link}/places" taget="_blank">Meus Anúncios</a>. Você encontrará todos os anúncios ativos e poderá editá-los sempre que precisar.`,
    },
    {
      title: "Como desativo um anúncio?",
      resume: `Navegação celular: após fazer o login, no menu fixo inferior, clique em`,
      description: `Navegação celular: após fazer o login, no menu fixo inferior, clique em perfil e acesse a opção <a href="${link}/places" taget="_blank">Meus Anúncios</a>. Navegação desktop: após fazer o login, no Menu superior à direita, clique em sua foto e acesse a opção <a href="${link}/places" taget="_blank">Meus Anúncios</a>. Você encontrará todos os anúncios ativos e poderá desativá-los quando quiser, mas os pedidos de locações aceitos não serão cancelados.`,
    },
    {
      title: "O que é um Período?",
      resume:
        "O Período em nosso sistema é a medida equivalente a 4 horas de locação e que",
      description:
        "O Período em nosso sistema é a medida equivalente a 4 horas de locação e que determina o cálculo do Preço básico da locação de seu espaço (exemplo: R$400,00 para o Período de 4h = Preço básico).",
    },
    {
      title: "Como edito o preço do meu espaço?",
      resume:
        "Navegação celular: entre com seu login e senha, no menu fixo inferior",
      description: `Navegação celular: entre com seu login e senha, no menu fixo inferior, clique em perfil e acesse a opção <a href="${link}/places" taget="_blank">Meus Anúncios</a>. Navegação desktop: entre com seu login e senha, e no canto superior direito, clique em sua foto e em <a href="${link}/places" taget="_blank">Meus Anúncios</a>. Nessa seção você encontrará a opção Editar preço e poderá definir o preço básico por Período de reserva (mínimo de 4 horas).`,
    },
    {
      title: "Como calculo o valor do meu espaço?",
      resume:
        "O cálculo é feito com base no número de períodos que você configurou",
      description: `O cálculo é feito com base no número de períodos que você configurou multiplicado pelo valor definido para o período. Importante: não se esqueça que o percentual Let Clinic será descontado sobre o valor final. <a href="${link}/terms/privacy-policy" title="Política de privacidade" taget="_blank">Clique aqui</a> e acesse nossos Política de preços.`,
    },
    {
      title: "Informações financeiras atualizadas",
      resume: "Certifique-se que seus dados bancários estão atualizados.",
      description:
        "Certifique-se que seus dados bancários estão atualizados. Navegação celular: entre com seu login e senha, e no menu fixo inferior, clique em perfil e selecione a opção “Minha conta” localizada embaixo da saudação com o seu nome de cadastro. Nessa seção você encontrará a opção Informações financeiras e Recebimento. Navegação desktop: entre com seu login e senha, e no canto superior direito, clique em sua foto e selecione a opção “Minha conta”. Nessa seção você encontrará a opção Informações financeiras e Recebimento.",
    },
    {
      title: "Informações de anúncio atualizadas",
      resume:
        "Mantenha seu calendário atualizado com as datas em que seu espaço",
      description:
        "Mantenha seu calendário atualizado com as datas em que seu espaço estará realmente disponível. Certifique-se que os horários inicial e final de seu Período também estão atualizados. Evite mal-entendidos com os valores e mantenha eles sempre atualizados.",
    },
    {
      title: "Anúncio mais atrativo",
      resume: "Descreva sua estrutura de forma atrativa, pontuando todas as",
      description:
        "Descreva o seu consultório de forma atrativa, pontuando todas as facilidades disponíveis em seu espaço compartilhado, incluindo comodidades e equipamentos. Compartilhe todas as informações importantes quanto ao cuidado com a sua estrutura e também instruções de entrada e saída do espaço. Inclua informações de contato, senha de Wi-Fi (caso forneça) e qualquer outra informação que ache relevante.",
    },
    {
      title: "Como realizo um pedido de locação?",
      resume:
        "Cadastre-se em nossa plataforma e forneça todas as informações necessárias. Seu",
      description:
        "Cadastre-se em nossa plataforma e forneça todas as informações necessárias. Seu cadastro precisa estar completamente preenchido antes de começar a locar um espaço. Feito isso, na página inicial (opção Quero alugar), selecione o tipo de consultório, digite onde você deseja atender e clique no botão buscar consultórios.",
    },
    {
      title: "Como funciona o botão agendar visita?",
      resume: "Através do botão agendar visita você pode solicitar uma visita",
      description:
        "Através do botão agendar visita você pode solicitar uma visita presencial no consultório selecionado antes de confirmar a sua reserva. Basta completar o seu cadastro na plataforma, selecionar o consultório que você possui interesse em conhecer pessoalmente e clicar no botão agendar visita.",
    },
    {
      title: "Como funciona a oferta especial para reservas de longa duração?",
      resume:
        "Alguns anúncios cadastrados na nossa plataforma oferecem uma oferta especial",
      description:
        "Alguns anúncios cadastrados na nossa plataforma oferecem uma oferta especial para reservas de longa duração (a partir de 15 períodos reservados). Os descontos variam de acordo com cada anúncio e para visualizar, basta clicar no anúncio do consultório e procurar a seção Oferta Especial para conferir o percentual de desconto.",
    },
    {
      title: "Como funciona o cupom de desconto?",
      resume: `Os proprietários dos consultórios podem criar cupons de desconto e`,
      description: `Os proprietários dos consultórios podem criar cupons de desconto e personalizar o código e porcentagem de desconto para cada anúncio ativo na plataforma e compartilhar esse código com os profissionais da saúde interessados em alugar o espaço. O cupom de desconto é válido para cada reserva solicitada e o valor de desconto não é cumulativo. Segue o passo a passo: 1. Realizar login e senha, 2. Selecionar o anúncio em "Meus anúncios”, 3. Na coluna "Preço e Desconto", clique no botão "Editar”, 4. Em "Cupons de desconto" personalize o Código e % de desconto e clique no botão "Criar" para salvar.`,
    },
    {
      title: "O que é o serviço Concierge Let?",
      resume:
        "Sem tempo para procurar? O serviço Concierge Let possui uma equipe especializada",
      description:
        "Sem tempo para procurar? O serviço Concierge Let possui uma equipe especializada para ajudar você a encontrar o consultório ideal com base nas suas preferências. O melhor de tudo é que este atendimento personalizado não possui custo adicional. Para falar com a Equipe Concierge Let, clique em um dos botões Concierge Let na nossa plataforma.",
    },
    {
      title: "Como falar com os proprietários dos consultórios?",
      resume:
        "É muito simples falar com os proprietários dos consultórios. Basta enviar uma mensagem através do chat Let Clinic:",
      description: `
      É muito simples falar com os proprietários dos consultórios. Basta enviar uma mensagem através do chat Let Clinic:
      <ul>
      <li>
      Clicar no link azul “Falar com o proprietário", localizado no final de cada anúncio
      </li>
      <li>
      Fazer o login ou cadastro 
      </li>
      <li>
      Clicar no campo "Digite uma mensagem" para iniciar a conversa
      </li>
      </ul>
      Para sua segurança e privacidade, a Let Clinic compartilha o endereço completo do consultório, somente após a reserva ser aprovada pelo proprietário do espaço. `,
    },
    {
      title: "Comunique e faça pagamentos pela Let Clinic?",
      resume:
        "É importante sempre manter todas as transações e comunicações na plataforma Let Clinic",
      description: `É importante sempre manter todas as transações e comunicações na plataforma Let Clinic, assim poderemos garantir que os pagamentos sejam processados de forma segura e que você tenha acesso ao nosso atendimento de cliente personalizado. Caso contrário, você perderá as proteções das nossas políticas de cancelamento e reembolso, <a href="${link}/terms/privacy-policy" taget="_blank">Termos e Condições de Uso e Política de Privacidade</a> e suporte pré, durante e pós reserva.
      <br /><br />
      Nós analisamos as mensagens na plataforma para garantir uma maior segurança para a nossa comunidade dos profissionais da saúde. Podemos bloquear mensagens suspeitas, incluindo informações de contato, links externos, referências a outros sites e conteúdo que viole os nossos <a href="${link}/terms/privacy-policy" taget="_blank">Termos e Condições de Uso e Política de Privacidade</a>.
.
      `,
    },
    // {
    //   title: "Como realizo uma vídeo consulta?",
    //   resume:
    //     "Cadastre-se em nossa rede e forneça todas as informações necessárias. Seu",
    //   description:
    //     "Cadastre-se em nossa rede e forneça todas as informações necessárias. Seu cadastro precisa estar completamente preenchido antes de começar a usar os serviços de vídeo consulta. Feito isso, combine com seu paciente a melhor data e horário para o atendimento. No dia da consulta, clique em Vídeo consulta, para gerar um código de acesso ao consultório on-line. Por fim compartilhe esse código com o seu paciente e pronto, é só começar o atendimento.",
    // },
    // {
    //   title: "Como faço para acessar meu consultório on-line?",
    //   resume:
    //     "Os consultórios on-line são gerados no ato em que você clica para criar a sala",
    //   description:
    //     "Os consultórios on-line são gerados no ato em que você clica para criar a sala da vídeo consulta. O código/link de acesso é pessoal e pode ser compartilhado com seu paciente.",
    // },
  ],
  // practices: [
  //   {
  //     title: "Dicas de boas práticas para uma vídeo consulta",
  //     body: `<h3>1 -  Estabilidade</h3>
  //            <p>Se necessário, usar um suporte para deixar a câmera do computador na altura dos olhos.</p>
  //            <h3>2 - Iluminação</h3>
  //            <p>Garantir que esteja num ambiente com iluminação adequada.</p>
  //            <h3>3 - Posicionamento</h3>
  //            <p>Buscar um enquadramento para que seu olhar esteja direcionado para a câmera, para assim o paciente se sentir acolhido.</p>
  //            <h3>4 - Som</h3>
  //            <p>Utilize fones de ouvido para garantir a melhor qualidade na sua fala. Se estiver usando fones bluetooth, se certificar de que eles estejam carregados.</p>
  //            <h3>5 - Conexão</h3>
  //            <p>Certifique-se de que você esteja com uma conexão de boa qualidade.</p>
  //            `,
  //   },
  //   {
  //     title: "Cuidados na vídeo consulta",
  //     body: `
  //             <p>• Quando gerar o link para seu consultório online, acesse com alguns minutos de antecedência para testar a qualidade de sua conexão.</p>
  //             <p>• Certifique-se do envio do link da sala para seu paciente. Sabendo que ele pode acessar a partir do momento do recebimento.</p>
  //             <p>• Não precisa baixar aplicativos para acessar a sala. Por isso, avise seus pacientes sobre essa questão e peça para o acesso ser feito pelo navegador.</p>
  //           `,
  //   },
  // ],
};
