import { useEffect } from "react";
import classNames from "classnames";

import CloseIcon from "@lc/ui/icons/Close";
import PreviousIcon from "@lc/ui/icons/Previous";

import useLockBodyScroll from "@lc/lib/hooks/useScrollLock";

import Button from "@lc/ui/components/Button";

import styles from "./modal.module.scss";

export type ModalProps = {
  children: React.ReactElement;
  isOpen: boolean;
  title?: React.ReactElement<Text>;
  backButton?: boolean;
  close: () => void;
  open: () => void;
};

const Modal = ({
  backButton,
  children,
  isOpen,
  close,
  title,
}: ModalProps): React.ReactElement => {
  const { lockBodyScroll, unlockBodyScroll } = useLockBodyScroll();

  useEffect(() => {
    if (isOpen) {
      lockBodyScroll();
    } else {
      unlockBodyScroll();
    }
  }, [isOpen, lockBodyScroll, unlockBodyScroll]);

  return (
    <>
      <div
        className={classNames({ [styles.backdrop]: isOpen })}
        onClick={close}
      ></div>
      <div
        className={classNames(styles.modal, {
          [styles.visible]: isOpen,
          [styles.hidden]: !isOpen,
        })}
      >
        <div className={styles.modalContent}>
          <div className={styles.header}>
            {backButton ? (
              <Button className={styles.backbutton} onClick={close} naked>
                <PreviousIcon size={24} />
              </Button>
            ) : null}
            {title}
            {!backButton ? (
              <Button className={styles.close} onClick={close} naked>
                <CloseIcon size={20} />
              </Button>
            ) : null}
          </div>
          {children}
        </div>
      </div>
    </>
  );
};

export default Modal;
