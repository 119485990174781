import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import "dayjs/locale/pt-br";

dayjs.extend(utc);
dayjs.locale("pt-br");

const formatDate = (date: string | Date, pattern: string) => {
  return dayjs.utc(new Date(date)).format(pattern);
};

export default formatDate;
