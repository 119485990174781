import routes from "@lc/lib/routes";
import gtag from "@lc/lib/helpers/gtag";

import replaceDiacritics from "./replace-diacritics";

function prepareAddressInfo(address) {
  const types = [
    "country",
    "administrative_area_level_1",
    "administrative_area_level_2",
    "administrative_area_level_4",
    "sublocality_level_1",
  ];

  const typesEquivalence = {
    country: "country",
    administrative_area_level_1: "state",
    administrative_area_level_2: "city",
    administrative_area_level_4: "neighborhood",
    sublocality_level_1: "neighborhood",
  };

  return address?.reduce((acc, value) => {
    const type = value.types[0];

    if (types.includes(type)) acc[typesEquivalence[type]] = value.long_name;

    return acc;
  }, {});
}

const replaceSpacesWithDash = (str) => str?.replace(/[\s+]+/g, "-");
const replaceDashWithSpaces = (str) => str?.replace(/[-+]+/g, " ");

const prepareParams = ({
  expertise = undefined,
  specialties = undefined,
  checkInDate = undefined,
  checkOutDate = undefined,
  addressObject,
  latlng = undefined,
  minPrice = undefined,
  maxPrice = undefined,
  query = undefined,
  lastId = undefined,
  isApi = false,
}) => {
  const params = new URLSearchParams("");

  let url = "";
  let urlPathParams = routes.public.search;

  if (expertise && isApi) {
    params.set("expertise", expertise);
    gtag.searchedExpertise(expertise);
  }
  if (expertise)
    urlPathParams += `/${replaceDiacritics(expertise).toLowerCase()}`;

  if (isApi && latlng) {
    params.set("lat", latlng.lat);
    params.set("lng", latlng.lng);
  }

  if (addressObject?.state && isApi) params.set("state", addressObject.state);
  if (addressObject?.city && isApi) params.set("city", addressObject.city);
  if (addressObject?.neighborhood && isApi)
    params.set("neighborhood", addressObject.neighborhood);

  if (addressObject?.state) urlPathParams += `/${addressObject.state}`;
  if (addressObject?.city) urlPathParams += `/${addressObject.city}`;
  if (addressObject?.neighborhood)
    urlPathParams += `/${addressObject.neighborhood}`;

  if (isApi && specialties?.length) {
    params.set("specialties", specialties);
  }
  if (!isApi && specialties?.length) {
    params.set(
      "specialties",
      specialties.map((item) => `${item.id}`).join(",")
    );
    gtag.searchedSpecialties(specialties);
  }
  if (minPrice) params.set("minPrice", minPrice);
  if (maxPrice) params.set("maxPrice", maxPrice);
  if (minPrice || maxPrice) {
    gtag.searchedPrices(minPrice, maxPrice);
  }

  // if (checkInDate) params.set('date_start', moment(checkInDate).format('YYYY-MM-DD'));
  // if (checkOutDate) params.set('date_end', moment(checkOutDate).format('YYYY-MM-DD'));
  // if (checkInDate || checkOutDate) {
  //   gtag.searchedDates(
  //     moment(checkInDate).format('DD-MM-YYYY'),
  //     moment(checkOutDate).format('DD-MM-YYYY'),
  //   );
  // }
  if (isApi && lastId) {
    params.set("last_id", lastId);
  }
  if (query?.length) {
    url += `&${query.join("&")}`;
  }

  if (isApi) {
    return `${url}&${params.toString()}`;
  }

  return `${replaceSpacesWithDash(urlPathParams)}${
    params.toString() ? "?" : ""
  }${params.toString()}`;
};
const search = {
  prepareParams,
  prepareAddressInfo,
  replaceSpacesWithDash,
  replaceDashWithSpaces,
};
export default search;
