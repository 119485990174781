import { useState, useCallback } from "react";

import Drawer from "@lc/ui/components/Drawer";

type useDrawerProps = {
  content: React.ReactElement;
  direction?: "LTR" | "RTL";
};

const useDrawer = ({ direction = "LTR", content }: useDrawerProps) => {
  const [open, setOpen] = useState(false);

  const openHelper = useCallback(() => setOpen(true), [setOpen]);
  const closeHelper = useCallback(() => setOpen(false), [setOpen]);

  return {
    openHelper,
    closeHelper,
    Drawer: (
      <Drawer direction={direction} open={open} onClose={closeHelper}>
        {content}
      </Drawer>
    ),
  };
};

export default useDrawer;
