import React, { useEffect, useRef } from "react";
import classNames from "classnames";

import useClickOutside from "@lc/lib/hooks/useClickOutside";
import useScrollLock from "@lc/lib/hooks/useScrollLock";

import styles from "./drawer.module.scss";

type Props = {
  direction?: "LTR" | "RTL";
  children: React.ReactElement;
  open: boolean;
  onClose: () => void;
};

const Drawer = (props: Props): React.ReactElement => {
  const { lockBodyScroll, unlockBodyScroll } = useScrollLock();
  const drawerRef = useRef<HTMLDivElement>(null);

  const { children, open, direction = "LTR", onClose } = props;

  const action = open ? `open` : `close`;

  useClickOutside(drawerRef, onClose);

  useEffect(() => {
    if (open) {
      lockBodyScroll();
    } else {
      unlockBodyScroll();
    }
  }, [lockBodyScroll, open, unlockBodyScroll]);

  return (
    <>
      <div className={classNames({ [styles.backdrop]: open })}></div>
      <div
        className={classNames(styles.drawer, styles[direction], styles[action])}
      >
        <div ref={drawerRef} className={styles.content}>
          {children}
        </div>
      </div>
    </>
  );
};

export default Drawer;
