import { ReactElement } from "react";
import styles from "./avatar.module.scss";

interface Props extends React.HTMLAttributes<HTMLElement> {
  children?: ReactElement | string;
  size?: string;
}

const Avatar = (props: Props): ReactElement => {
  const { children, size, style, ...rest } = props;

  return (
    <div
      className={styles.avatar}
      style={{
        ...style,
        ...(size
          ? {
              minHeight: size,
              minWidth: size,
              maxWidth: size,
              maxHeight: size,
            }
          : {}),
      }}
      {...rest}
    >
      {children}
    </div>
  );
};

export default Avatar;
