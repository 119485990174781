import api from "./apiConfig";
import nextjs from "./nextjsAPI";
import chat from "./chat";
import notification from "./notification";
import phone from "./phone";
import log from "./log";

const getExpertises = async () => api.get({ url: "v1/expertise" });
const searchHighlightedAdvertising = async () =>
  api.get({ url: "v1/search/room/highlighted" });
const hasNotifications = async () => api.get({ url: "v1/notifications" });

const all: any = {
  getExpertises,
  searchHighlightedAdvertising,
  hasNotifications,
  chat,
  notification,
  phone,
  log,
  email: {
    activeEmail: async (token: string) =>
      api.post({ url: `v1/users/active/${token}` }),
    resendEmail: async (data: any) =>
      api.post({ url: `v1/users/resend-email`, body: data }),
  },
  nextjs: { ...nextjs },
};

export default all;
