import React, { useEffect, useState } from "react";
import classNames from "classnames";
import { useResponsive } from "@lc/lib/hooks/useResponsive";

import styles from "./text.module.scss";

type StyleAs =
  | "h1"
  | "h2"
  | "h3"
  | "h4"
  | "h5"
  | "h6"
  | "body1"
  | "body2"
  | "body3";

type ComponentProps = {
  children?: React.ReactElement | string | React.ReactNode;
  styleAs?: StyleAs;
  styleAsOnSm?: StyleAs;
  styleAsOnMd?: StyleAs;
  styleAsOnLg?: StyleAs;
  element?: "p" | "span" | "h1" | "h2" | "h3" | "h4" | "h5" | "h6" | "div";
  className?: string;
  color?: "primary" | "green" | "secondary" | "white";
  noMargin?: boolean;
  noOverflow?: boolean;
};

export type TextProps = ComponentProps &
  React.HTMLAttributes<HTMLParagraphElement>;

const Text = (props: TextProps): React.ReactElement => {
  const {
    children,
    color = "primary",
    styleAs = "body1",
    styleAsOnSm = undefined,
    styleAsOnMd = undefined,
    styleAsOnLg = undefined,
    element: Tag = "p",
    className,
    noMargin = false,
    noOverflow = false,
    ...rest
  } = props;

  const [responsiveStyle, setResponsiveStyle] = useState<StyleAs>(styleAs);
  const { sm, md, lg } = useResponsive();

  useEffect(() => {
    let stl = styleAs;
    if (sm && styleAsOnSm) stl = styleAsOnSm;
    if (md && styleAsOnMd) stl = styleAsOnMd;
    if (lg && styleAsOnLg) stl = styleAsOnLg;

    setResponsiveStyle(stl);
  }, [sm, md, lg, styleAs, styleAsOnSm, styleAsOnMd, styleAsOnLg]);

  return (
    <>
      <Tag
        {...rest}
        className={classNames(
          styles.common,
          styleAs,
          className,
          styles[color],
          styles[responsiveStyle],
          {
            [styles.noMargin]: noMargin,
            [styles.noOverflow]: noOverflow,
          }
        )}
      >
        {children}
      </Tag>
    </>
  );
};

export default Text;
