import React from "react";
import type { AppProps } from "next/app";
import Head from "next/head";

import MetaDescription from "@lc/ui/template/MetaDescription";
import ErrorBoundary from "@lc/ui/template/ErrorBoundary";
import { isProduction } from "@lc/lib/config";
import Provider from "@lc/lib/Provider";

import ProdScripts from "src/components/Scripts/Prod";
import DevScripts from "src/components/Scripts/Dev";

import "@lc/ui/styles/globals.scss";

function MyApp({ Component, pageProps }: AppProps): React.ReactElement {
  const { metaTags } = pageProps;

  return (
    <ErrorBoundary>
      <Provider>
        <Head>
          <meta
            name="viewport"
            content="width=device-width, initial-scale=1.0"
          />
        </Head>

        {metaTags ? (
          <MetaDescription
            title={metaTags.title}
            description={metaTags.description}
            image={metaTags.image}
            url={metaTags.url}
          />
        ) : (
          <MetaDescription
            title="Let Clinic - Alugue - Consultórios Completos - Pague pelo uso"
            description="Quer alugar um consultório? Atenda seus pacientes de onde quiser pagando apenas por períodos utilizados. Seu consultório completo sem despesas fixas e total segurança!"
            image="https://letclinic.com/images/metaimage.jpg"
            url="https://letclinic.com/"
          />
        )}

        {isProduction && <ProdScripts noScript />}
        <Component {...pageProps} />
        {isProduction ? <ProdScripts /> : <DevScripts />}
      </Provider>
    </ErrorBoundary>
  );
}

export default MyApp;
