import apisauce, {
  ApiErrorResponse,
  ApiOkResponse,
  ApiResponse,
} from "apisauce";

import Router from "next/router";

import routes from "../routes";
import { getUserAuthKey, removeUserData } from "../helpers/userData";
import logger from "../helpers/logger";
import { letClinicBaseUrl } from "../config";

export const logHttpError = new Map([
  [
    "SERVER_ERROR",
    (prefix, err) => {
      const errorMsgDetail = err.message ? `: ${err.message}` : "";
      logger.logError(`${prefix} SERVER_ERROR${errorMsgDetail}`, {
        error: err,
      });
    },
  ],
  [
    "TIMEOUT_ERROR",
    (prefix, err) => {
      const errorMsgDetail = err.message ? `: ${err.message}` : "";
      logger.logError(`${prefix} TIMEOUT_ERROR${errorMsgDetail}`, {
        error: err,
      });
    },
  ],
  [
    "CONNECTION_ERROR",
    (prefix, err) => {
      const errorMsgDetail = err.message ? `: ${err.message}` : "";
      logger.logError(`${prefix} CONNECTION_ERROR${errorMsgDetail} `, {
        error: err,
      });
    },
  ],
  [
    "NETWORK_ERROR",
    (prefix, err) => {
      const errorMsgDetail = err.message ? `: ${err.message}` : "";
      logger.logError(`${prefix} NETWORK_ERROR${errorMsgDetail}`, {
        error: err,
      });
    },
  ],
]);

const urlToNotLogInSentry = [
  { error: "NETWORK_ERROR", url: "v1/notifications" },
];

const shouldLog = (response: ApiOkResponse<any> | ApiErrorResponse<any>) => {
  return urlToNotLogInSentry.some((item) => {
    if (response.problem === item.error && item.url === response?.config?.url) {
      return false;
    }
    return true;
  });
};

const api = apisauce.create({
  baseURL: letClinicBaseUrl,
  headers: {
    "Cache-Control": "no-cache",
    Pragma: "no-cache",
    Expires: "0",
    Accept: "*/*",
    "Content-Type": "application/json",
  },
  timeout: 45000,
});

api.addRequestTransform((request) => {
  if (
    ["localhost", "letclinic"].some(
      (domain) => request?.baseURL?.indexOf(domain) !== -1
    )
  ) {
    if (getUserAuthKey()) request.headers.Authorization = getUserAuthKey();
  }
  // console.log('request: ', request);
});

export type ResponseProps = {
  data: any;
  hasError: boolean;
};

const handleResponse = (
  promise: Promise<ApiOkResponse<any> | ApiErrorResponse<any>>
): Promise<ResponseProps> =>
  promise
    .then(async (response) => {
      if (response.ok) {
        return { data: response.data, hasError: false };
      }

      if (
        !response.ok &&
        logHttpError.has(response.problem) &&
        shouldLog(response)
      ) {
        logHttpError.get(response.problem)("main", response.originalError);
      } else if (response.status !== 401 && shouldLog(response)) {
        logger.logError("handleResponse nOK", response.originalError);
      }

      if (response.status === 401) {
        removeUserData();
        if (
          window.location.pathname.indexOf(routes.public.login) !== -1 &&
          window.location.pathname.indexOf(routes.public.signupForm) !== -1
        ) {
          Router.replace(
            `${routes.public.login}?returnUrl=${window.location.href}`
          );
        }
        return { data: { statusCode: 401 }, hasError: true };
      }
      return { data: response, hasError: true };
    })
    .catch((error) => {
      if (logHttpError.has(error.problem)) {
        logHttpError.get(error.problem)("main", error);
      } else {
        logger.logError("handleResponse", error);
      }
      return { data: error, hasError: true };
    });

type GET_PROPS = {
  url: string;
  extras?: any;
};
const get = ({ url, extras }: GET_PROPS) =>
  handleResponse(api.get(url, undefined, extras));

type POST_PROPS = {
  url: string;
  body?: any;
  extras?: any;
};
const post = ({ url, body = {}, extras }: POST_PROPS) =>
  handleResponse(api.post(url, body, extras));

type PUT_PROPS = {
  url: string;
  body?: any;
  extras?: any;
};
const put = ({ url, body = {}, extras }: PUT_PROPS) =>
  handleResponse(api.put(url, body, extras));

type PATCH_PROPS = {
  url: string;
  body?: any;
  extras?: any;
};
const patch = ({ url, body = {}, extras }: PATCH_PROPS) =>
  handleResponse(api.patch(url, body, extras));

const all = { get, post, put, patch };
export default all;
