import { useState } from "react";
import throttle from "lodash/throttle";

import { OptionsType } from "@lc/ui/components/Form/Suggestions";
import api from "../api";

import search from "../helpers/search";

const useFetchSearch = (): {
  getPredictions: Function;
  predictions: OptionsType[];
  getSearchUrl: Function;
} => {
  const [predictions, setPredictions] = useState<OptionsType[]>([]);

  const getPredictions = throttle(async (term: string) => {
    const { data, hasError } = await api.nextjs.getPredictions(term);

    if (!hasError) {
      setPredictions(
        data.predictions.map(
          (predi: { place_id: string; description: string }) => ({
            id: predi.place_id,
            description: predi.description,
            extras: predi,
          })
        )
      );
    }
  }, 300);

  const getSearchUrl = async ({ place, expertise }: any): Promise<string> => {
    let placeData;
    if (place) {
      const { data, hasError } = await api.nextjs.getPlaceDetail(
        place.id as string
      );
      if (!hasError) {
        placeData = data.result.address_components;
      }
    }
    const url = search.prepareParams({
      expertise,
      addressObject: search.prepareAddressInfo(placeData),
    });

    return url;
  };

  return { getPredictions, predictions, getSearchUrl };
};

export default useFetchSearch;
