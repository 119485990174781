import api, { ResponseProps } from "../apiConfig";

interface NotificationResponseData extends ResponseProps {
  data: {
    id: string;
    type: string;
    read: boolean;
    created_at: string;
    booking_code: string;
    room_name: string;
    room_address: string;
  };
}

const notification = {
  listHosterNotification: async (): Promise<NotificationResponseData> =>
    api.get({ url: `/v1/notifications/hoster` }),

  listBookerNotification: async (): Promise<NotificationResponseData> =>
    api.get({ url: `/v1/notifications/booker` }),
  getNotification: async (id: string): Promise<NotificationResponseData> =>
    api.get({ url: `/v1/notifications/${id}` }),
};

export default notification;
