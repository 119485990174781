import { useEffect } from "react";

const useClickOutside = (
  ref: React.RefObject<HTMLElement> | undefined,
  cb: (param: MouseEvent | TouchEvent) => MouseEvent | void
): void => {
  useEffect(() => {
    const listener = (event: MouseEvent | TouchEvent): void => {
      if (ref?.current?.contains(event.target as Node)) {
        return;
      }

      cb(event);
    };

    document.addEventListener("click", listener, true);
    document.addEventListener("touchstart", listener, true);

    return (): void => {
      document.removeEventListener("click", listener, true);
      document.removeEventListener("touchstart", listener, true);
    };
  }, [ref, cb]);
};

export default useClickOutside;
