import api from './apiConfig';

export const GoogleMapsUrl = 'https://maps.googleapis.com/maps/api';
export const googleApiKey = 'AIzaSyDzhT0j1VtWH9MmZaIiywqWoieCcEDYqWg';

const nextjs = {
  getPredictions: (input: string) =>
    api.get({ url: `api/google/predictions?input=${input}`, extras: { baseURL: '/' } }),
  getPlaceDetail: (id: string) =>
    api.get({ url: `api/google/place?id=${id}`, extras: { baseURL: '/' } }),
};

export default nextjs;
