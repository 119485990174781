import React, { useEffect } from "react";
import { useRouter } from "next/router";

import { ErrorMessages } from "@lc/lib/data/errorMessages";
import routes from "@lc/lib/routes";

import Button from "@lc/ui/components/Button";
import Text from "@lc/ui/components/Text";
import Image from "@lc/ui/components/Image";
import Layout from "@lc/ui/template/Layout";
import logger from "@lc/lib/helpers/logger";

const ErrorPage = ({ statusCode }: { statusCode: number }) => {
  const router = useRouter();

  useEffect(() => {
    if (statusCode !== 404) {
      logger.logError(`ErrorPage ${statusCode}`, { url: router.asPath });
    }
  }, [router.asPath, statusCode]);

  return (
    <Layout showFooter={false}>
      <div
        style={{
          margin: "0 auto",
          flex: 1,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          textAlign: "center",
        }}
      >
        <Text styleAs="h2">
          {ErrorMessages[statusCode]?.title || ErrorMessages[0].title}
        </Text>
        <Text>{ErrorMessages[statusCode]?.more || ErrorMessages[0].more}</Text>

        <Button
          asLink
          color="secondary"
          variant="contained"
          href={routes.public.whatsapp}
          newWindow
        >
          <>
            <Image
              src="images/social/whatsapp-green-solid.svg"
              alt="whatsapp icon"
              height="24px"
              width="24px"
            />
            Fale conosco
          </>
        </Button>
        <Text styleAs="body3" color="secondary">
          URL: <code style={{ wordBreak: "break-all" }}>{router.asPath}</code>
        </Text>
      </div>
    </Layout>
  );
};

export default ErrorPage;
