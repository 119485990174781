import { ReactElement, RefObject, useState, useEffect } from "react";
import classNames from "classnames";
import { IconBaseProps } from "react-icons/lib";

import { useResponsive } from "@lc/lib/hooks/useResponsive";
import Oval from "@lc/ui/spinner/Oval";
import Link from "@lc/ui/components/Link";

import styles from "./button.module.scss";

type ButtonSizeProps = "xs" | "sm" | "md" | "lg";
interface Props extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  innerRef?: RefObject<HTMLButtonElement> | null;
  label?: string;
  color?: "primary" | "secondary";
  children?: ReactElement | string;
  href?: string;
  naked?: boolean;
  noPadding?: boolean;
  asLink?: boolean;
  newWindow?: boolean;
  loading?: boolean;
  variant?: "contained" | "outline";
  fullWidth?: boolean;
  size?: ButtonSizeProps;
  sizeOnSm?: ButtonSizeProps;
  sizeOnMd?: ButtonSizeProps;
  sizeOnLg?: ButtonSizeProps;
  icon?: IconBaseProps;
  justifyContent?: "flex-start" | "center" | "flex-end";
}

const Button = (props: Props): ReactElement => {
  const {
    innerRef = null,
    children,
    className,
    color = "primary",
    icon,
    label,
    href,
    disabled = false,
    asLink = false,
    newWindow = false,
    naked = false,
    noPadding = false,
    loading = false,
    fullWidth = false,
    size = "sm",
    sizeOnSm = undefined,
    sizeOnMd = undefined,
    sizeOnLg = undefined,
    variant = "contained",
    justifyContent = "center",
    style,
    ...rest
  } = props;

  const [responsiveStyle, setResponsiveStyle] = useState<ButtonSizeProps>(size);
  const { sm, md, lg } = useResponsive();
  const classes = naked ? [] : [styles[color], styles[variant]];

  useEffect(() => {
    let sz = size;
    if (lg && sizeOnLg) sz = sizeOnLg;
    if (md && sizeOnMd) sz = sizeOnMd;
    if (sm && sizeOnSm) sz = sizeOnSm;

    setResponsiveStyle(sz);
  }, [sm, md, lg, size, sizeOnSm, sizeOnMd, sizeOnLg]);

  const renderButtonChildren = ({
    children,
    label,
  }: {
    children: ReactElement | string | undefined;
    label: string | undefined;
  }) =>
    children ? (
      children
    ) : (
      <>
        <span className={styles.icon}>{icon}</span>
        {label}
      </>
    );
  const renderButton = (
    <>
      <button
        ref={innerRef}
        {...rest}
        disabled={disabled || loading}
        className={classNames(styles.button, classes, styles[responsiveStyle], {
          [styles.naked]: naked,
          [styles.noPadding]: noPadding,
          [styles.fullWidth]: fullWidth,
          [styles.loading]: loading,
          [styles.asLink]: asLink,
          [className || ""]: !!className,
        })}
        style={{
          ...style,
          justifyContent: justifyContent,
        }}
      >
        {loading ? <Oval /> : renderButtonChildren({ children, label })}
      </button>
    </>
  );

  return href ? (
    <Link naked newWindow={newWindow} noUnderline href={href}>
      {renderButton}
    </Link>
  ) : (
    renderButton
  );
};

export default Button;
