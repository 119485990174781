interface ErrorProps {
  [key: number]: { title: string; more: string };
}
export const ErrorMessages: ErrorProps = {
  404: {
    title: "Ops! Página não encontrada",
    more: "Caso seja necessário contate nosso suporte!",
  },
  1: {
    title:
      "Algo aconteceu em nossos servidores, por favor atualize a página e tente novamente.",
    more: "Caso seja necessário contate nosso suporte!",
  },
  0: {
    title: "Ops! Aconteceu um erro interno",
    more: "Caso seja necessário contate nosso suporte!",
  },
};
