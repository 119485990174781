import React, {
  useEffect,
  useCallback,
  MouseEvent,
  RefObject,
  useRef,
  useState,
} from "react";
import classNames from "classnames";

import styles from "./suggestions.module.scss";

export type OptionsType = {
  id: string | number;
  description: string;
  extras?: unknown;
};

type SuggestionsProps = {
  optionsOffset: number | undefined;
  options: OptionsType[] | undefined;
  onChoose: (data: OptionsType) => void;
  inputRef: RefObject<HTMLInputElement>;
  qaLabelPrefix?: string;
};

const Suggestions = ({
  options,
  optionsOffset = 60,
  qaLabelPrefix = "",
  inputRef,
  onChoose,
}: SuggestionsProps): React.ReactElement | null => {
  const [active, setActive] = useState<number>(-1);
  const listRef = useRef<HTMLUListElement>(null);

  const handleClick = (
    e: MouseEvent,
    item: OptionsType,
    index: number
  ): void => {
    e.preventDefault();
    e.stopPropagation();
    setActive(index);
    onChoose(item);
  };

  const handleKeyDown = useCallback(
    (e: KeyboardEvent) => {
      if (options === undefined) return;

      const lenght = options.length - 1;
      if ("ArrowDown" === e.key) {
        e.preventDefault();
        const nextIndex = active + 1;
        if (nextIndex > lenght) {
          setActive(0);
        } else {
          setActive(nextIndex);
        }
      }
      if ("ArrowUp" === e.key) {
        e.preventDefault();
        const nextIndex = active - 1;
        if (nextIndex < 0) {
          setActive(lenght);
        } else {
          setActive(nextIndex);
        }
      }
      if ("Enter" === e.key && options[active]) {
        onChoose(options[active]);
      }
    },
    [active, onChoose, options]
  );

  useEffect(() => {
    const input = inputRef.current;
    input?.addEventListener("keydown", handleKeyDown);

    return (): void => {
      input?.removeEventListener("keydown", handleKeyDown);
    };
  }, [handleKeyDown, inputRef]);

  return (
    <ul
      data-qalabel={`${qaLabelPrefix}-suggestions`}
      className={styles.autocomplete}
      ref={listRef}
      style={{ top: `${optionsOffset}px` }}
    >
      {options?.map((item, index) => (
        <li
          data-qalabel={`${qaLabelPrefix}${item.description}`}
          key={item.id}
          className={classNames(styles.item, {
            [styles.active]: index === active,
          })}
          onClick={(e): void => handleClick(e, item, index)}
        >
          {item.description}
        </li>
      ))}
    </ul>
  );
};

export default Suggestions;
