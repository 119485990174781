const routes = {
  public: {
    home: "/",
    login: "/login",
    signupForm: "/signup-form",
    hosterHome: "/compartilhar-consultorio",
    booking: (id: string): string => `/booking/${id}`,
    search: "/alugar-consultorios",
    searchByExpertise: (expertise: string): string =>
      `/alugar-consultorios/${expertise}`,
    terms: { termsOfService: "/terms/terms-of-service" },
    mailto: "mailto:contato@letclinic.com",
    blog: "https://blog.letclinic.com/",
    social: {
      fb: "https://www.facebook.com/letclinic/",
      ig: "https://www.instagram.com/letclinic/",
      in: "https://www.linkedin.com/company/letclinic/",
      twitter: "https://twitter.com/letclinic",
      yt: "https://www.youtube.com/channel/UCrf23P_Lhil15zEZXJt3NzQ",
    },
    whatsapp: "https://api.whatsapp.com/send?phone=5511932790941",
  },
  internal: {
    myBooking: (id: string) => `/booking/list/${id}`,
    bookingDetail: (id: string): string => `/booking/details/${id}`,
    bookingPaymentMethod: (id: string): string => `/booking/payment/${id}`,
    bookingPaymentCC: (id: string): string => `/booking/payment/cc/${id}`,
    bookingPaymentPix: (id: string): string => `/booking/payment/pix/${id}`,
    account: "/account",
    notification: "/notification",
    chat: "/chat",
    openChat: (type, id) => `/chat/open/${type}/${id}`,
    chatConversation: (id) => `/chat/${id}`,
    place: {
      list: "/places",
      register: "/place/01",
    },
    logout: "/logout",
  },
};

export default routes;
