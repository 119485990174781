import api, { ResponseProps } from "../apiConfig";

interface PhoneResponseData extends ResponseProps {
  data: {
    id: string;
    country_code: string;
    number: string;
    validated: boolean;
  };
}

const phone = {
  getPhone: async (): Promise<PhoneResponseData> =>
    api.get({ url: `/v1/users/phone` }),
  savePhone: (phone: string) =>
    api.post({ url: "v1/contacts/phone", body: phone }),
  updatePhone: (id: string, phone: string) =>
    api.put({ url: `v1/contacts/phone/${id}`, body: phone }),
  resendSmsPhoneNumber: (id) =>
    api.put({ url: `v1/contacts/phone/${id}/resend-sms` }),
  activePhoneNumber: (id, phoneData: { validation_code: string }) =>
    api.patch({ url: `v1/contacts/phone/${id}/active`, body: phoneData }),
};

export default phone;
