import { useCallback, useEffect, useState } from "react";
import {
  getUserAuthKey,
  getUserData,
  removeUserData,
  setUserAuthKey,
  setUserData,
} from "@lc/lib/helpers/userData";
import logger from "../../helpers/logger";

const isServer = typeof window === "undefined";
export interface userDataType {
  id: string;
  created_at: Date;
  updated_at: Date;
  document: string;
  document_front: string;
  document_back: string;
  document_type: string;
  steps: {
    email: boolean;
    phone: boolean;
    avatar: boolean;
    address: boolean;
    documents: boolean;
    occupation_area: boolean;
  };
  user_id: string;
  user: {
    id: string;
    created_at: Date;
    updated_at: Date;
    first_name: string;
    last_name: string;
    avatar: string;
    email: string;
    status: boolean;
    validation_code: string;
    provider_id: string;
    provider_name: string;
    provider_token: string;
  };
  address: {
    id: string;
    created_at: Date;
    updated_at: Date;
    zipcode: string;
    neighborhood: string;
    street: string;
    city: string;
    state: string;
    number: string;
    country: string;
    complement: string;
    lat: string;
    lng: string;
    address_type: string;
    status: boolean;
  };
  phones: {
    id: string;
    created_at: Date;
    updated_at: Date;
    deleted_at: Date;
    number: string;
    country_code: string;
    phone_type: string;
    validation_code: string;
    active_code: boolean;
    status: boolean;
  }[];
  occupation: {
    id: string;
    created_at: Date;
    updated_at: Date;
    record_number: string;
    issuer_state: string;
    health_professional: "YES" | "NO";
    expertise_id: {
      id: string;
      created_at: Date;
      updated_at: Date;
      description: string;
    };
    specialties: [];
  };
}

export interface useUserProps {
  userData: userDataType | undefined;
  isLogged: boolean | undefined;
  saveUserData: (payload: any) => void | boolean;
  updateUserData: (payload: any) => void | boolean;
  cleanUserData: () => void;
}

/**
 * 
 * @description Não use isso diretamente, use o contexto import { useUserInfo } from '@lc/lib/Provider', use diretamente apenas no _app.js
 */
const useUser = (): useUserProps => {
  const [, updateState] = useState<{}>();
  const forceUpdate = useCallback(() => updateState({}), []);

  const profileCookie = getUserData();
  const userKey = getUserAuthKey();
  const user = profileCookie ? JSON.parse(profileCookie) : undefined;

  const saveUserData = useCallback((payload) => {
    if (payload?.profile && payload?.access_token) {
      setUserAuthKey(
        `Bearer ${payload.access_token}`,
        new Date(new Date().getTime() + Number(payload.expires_in))
      );
      setUserData(JSON.stringify(payload.profile));
      forceUpdate();
      return true;
    } else {
      logger.logError("No profile or access_token in login response", payload);
      return false;
    }
  }, []);

  const updateUserData = useCallback((payload) => {
    if (payload?.profile) {
      setUserData(JSON.stringify(payload.profile));
      forceUpdate();
      return true;
    } else {
      logger.logError("No profile updateUserData response", payload);
      return false;
    }
  }, []);

  useEffect(() => {
    if (Boolean(user) === false && Boolean(userKey) === false) {
      removeUserData();
    }
  }, [user, userKey]);

  return {
    isLogged: isServer ? undefined : Boolean(user),
    userData: user,
    saveUserData,
    updateUserData,
    cleanUserData: removeUserData,
  };
};

export default useUser;
