import React from "react";
import classNames from "classnames";
import NextLink, { LinkProps } from "next/link";

import Text, { TextProps } from "../Text";

import styles from "./link.module.scss";

interface ComponentProp extends React.AnchorHTMLAttributes<HTMLAnchorElement> {
  children: React.ReactElement | string;
  className?: string;
  qaLabel?: string;
  naked?: boolean;
  noUnderline?: boolean;
  newWindow?: boolean;
  textProps?: TextProps;
}

type Props = ComponentProp & LinkProps;

const Link = (props: Props): React.ReactElement => {
  const {
    children,
    naked = false,
    noUnderline = false,
    className,
    textProps,
    newWindow = false,
    href,
    qaLabel = "",
    ...rest
  } = props;

  return (
    <Text element="span" {...textProps}>
      <span
        className={classNames(styles.link, className, {
          [styles.naked]: naked,
          [styles.noUnderline]: noUnderline,
        })}
      >
        {newWindow ? (
          <a
            data-qalabel={qaLabel}
            target="_blank"
            rel="noreferrer"
            href={href.toString()}
            {...rest}
          >
            {children}
          </a>
        ) : (
          <NextLink href={href.toString()} {...rest}>
            <a data-qalabel={qaLabel}>{children}</a>
          </NextLink>
        )}
      </span>
    </Text>
  );
};

export default Link;
