import React, { useCallback, useState } from "react";
import Modal from "@lc/ui/components/Modal";

type Props = {
  Modal: React.ReactElement;
  isModalOpen: boolean;
  openModal: Function;
  closeModal: () => void;
};

type useModalProps = {
  content: ({
    innerCloseModal,
    isOpen,
  }: {
    innerCloseModal: () => void;
    isOpen: boolean;
  }) => React.ReactElement;
  title?: React.ReactElement;
  backButton?: boolean;
};

const useModal = ({ content, title, backButton }: useModalProps): Props => {
  const [open, setOpen] = useState(false);

  const openModal = useCallback(() => setOpen(true), [setOpen]);
  const closeModal = useCallback(() => setOpen(false), [setOpen]);

  return {
    Modal: (
      <Modal
        isOpen={open}
        close={closeModal}
        open={openModal}
        title={title}
        backButton={backButton}
      >
        {content({ innerCloseModal: closeModal, isOpen: open })}
      </Modal>
    ),
    isModalOpen: open,
    openModal,
    closeModal,
  };
};

export default useModal;
