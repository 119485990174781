import * as Sentry from "@sentry/nextjs";

export default {
  logError: (message: string, data: any) => {
    const error = data?.error;
    Sentry.captureEvent({
      message,
      level: Sentry.Severity.Error,

      extra: {
        ...data,
        ...(error ? { errorStringify: JSON.stringify(error) } : {}),
      },
    });
  },
  logInfo: (message: string, data: any) => {
    Sentry.captureEvent({
      message,
      level: Sentry.Severity.Info,

      extra: { ...data },
    });
  },
};
