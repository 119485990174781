import { FormEvent } from "react";
import SearchOutlineIcon from "@lc/ui/icons/SearchOutline";
import Router from "next/router";

import useFetchSearch from "@lc/lib/hooks/useFetchSearch";

import Button from "@lc/ui/components/Button";
import Input from "@lc/ui/components/Form/Input";
import { OptionsType } from "@lc/ui/components/Form/Suggestions";

import styles from "./searchInput.module.scss";

const SearchInput = (): React.ReactElement => {
  const { getPredictions, predictions, getSearchUrl } = useFetchSearch();

  const handleOnChange = (e: FormEvent<HTMLInputElement>): void => {
    getPredictions(e.currentTarget.value);
  };

  const onChoose = async (place: OptionsType): Promise<void> => {
    const url = await getSearchUrl({ place });
    Router.push(url);
  };

  const onSubmit = (e: FormEvent<HTMLFormElement>): void => {
    e.preventDefault();
    onChoose(predictions[0]);
  };

  return (
    <form className={styles.wrapper} onSubmit={onSubmit}>
      <Input
        onChoose={onChoose}
        onChange={handleOnChange}
        options={predictions}
        placeholder="Onde você quer atender?"
        variant="naked"
      />
      <Button aria-label="Buscar" color="secondary" naked>
        <SearchOutlineIcon className={styles.icon} />
      </Button>
    </form>
  );
};

export default SearchInput;
