import Cookies from "js-cookie";
//TOOD: use cookie server side as well
const isServer = typeof window === "undefined";

enum DataTypes {
  LC_AUTHTORIZATION = "lc_authorization",
  LC_PROFILE = "lc_profile",
}

export const setUserAuthKey = (authKey: string, expires: Date) => {
  Cookies.set(DataTypes.LC_AUTHTORIZATION, authKey, { expires });
};
export const setUserData = (profileData) => {
  if (isServer) return;
  localStorage.setItem(DataTypes.LC_PROFILE, profileData);
};

export const getUserAuthKey = () => {
  return Cookies.get(DataTypes.LC_AUTHTORIZATION);
};

export const getUserData = () => {
  const authCookie = Cookies.get(DataTypes.LC_AUTHTORIZATION);
  if (authCookie && !isServer) {
    return localStorage.getItem(DataTypes.LC_PROFILE);
  }
  return undefined;
};

export const removeUserData = () => {
  Cookies.remove(DataTypes.LC_AUTHTORIZATION);
  if (!isServer) localStorage.removeItem(DataTypes.LC_PROFILE);
};
