import React from "react";
import Script from "next/script";

import appConfig from "@lc/lib/config";

const ProdScripts = ({
  noScript,
}: {
  noScript?: boolean;
}): React.ReactElement => {
  if (noScript) {
    return (
      <>
        {/* Facebook Pixel Code */}
        <noscript
          dangerouslySetInnerHTML={{
            __html: `<img
              height="1"
              width="1"
              style="display:none"
              src="https://www.facebook.com/tr?id=${appConfig.FB_PIXEL_CODE}&ev=PageView&noscript=1"
            `,
          }}
        />
        {/* Google Tag Manager (noscript) */}
        <noscript
          dangerouslySetInnerHTML={{
            __html: `<iframe src="https://www.googletagmanager.com/ns.html?id=GTM-TZJMK8P"
              height="0" width="0" style="display:none;visibility:hidden"></iframe>`,
          }}
        />
        {/* Linkedin? (noscript) */}
        <noscript
          dangerouslySetInnerHTML={{
            __html: `<img height="1" width="1" style="display:none;" alt="" src="https://px.ads.linkedin.com/collect/?pid=2065593&fmt=gif" />`,
          }}
        />
      </>
    );
  }
  return (
    <>
      {/* RDStation */}
      <Script
        strategy="afterInteractive"
        src="https://d335luupugsy2.cloudfront.net/js/loader-scripts/47a5a748-46d9-48a8-8bc8-a58679158a5a-loader.js"
      />
      {/* Google Tag Manager */}
      <Script
        id="gtmscripts"
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
              new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
              j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
              'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
              })(window,document,'script','dataLayer','GTM-TZJMK8P');`,
        }}
      />

      {/* Global Site Tag (gtag.js) - Google Analytics */}
      <Script
        strategy="afterInteractive"
        src={`https://www.googletagmanager.com/gtag/js?id=${appConfig.GA_TRACKING_ID}`}
      />
      {/* Global site tag (gtag.js) - Google Ads: 654946432 */}
      <Script
        strategy="afterInteractive"
        src={`https://www.googletagmanager.com/gtag/js?id=${appConfig.GA_ADS_ID}`}
      />

      {/* Facebook Pixel Code */}
      <Script
        id="fbscripts"
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `
              !function(f,b,e,v,n,t,s)
              {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
              n.callMethod.apply(n,arguments):n.queue.push(arguments)};
              if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
              n.queue=[];t=b.createElement(e);t.async=!0;
              t.src=v;s=b.getElementsByTagName(e)[0];
              s.parentNode.insertBefore(t,s)}(window, document,'script',
              'https://connect.facebook.net/en_US/fbevents.js');
              fbq('init', '${appConfig.FB_PIXEL_CODE}');
              fbq('track', 'PageView');
          `,
        }}
      />

      <Script
        id="gaadsscripts"
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', '${appConfig.GA_ADS_ID}');
          `,
        }}
      />
      <Script
        id="gatrackinscript"
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `
              window.dataLayer = window.dataLayer || [];
              function gtag(){dataLayer.push(arguments);}
              gtag('js', new Date());
              gtag('config', '${appConfig.GA_TRACKING_ID}');
          `,
        }}
      />
      {/* Unexpected token '<' was occuring with <Script... I dunno */}
      <script
        id="linkedinscript"
        async
        dangerouslySetInnerHTML={{
          __html: `
              _linkedin_partner_id = "[2065593|tel:2065593]";
              window._linkedin_data_partner_ids = window._linkedin_data_partner_ids || [];
              window._linkedin_data_partner_ids.push(_linkedin_partner_id);
              </script><script type="text/javascript">
              (function(){var s = document.getElementsByTagName("script")[0];
              var b = document.createElement("script");
              b.type = "text/javascript";b.async = true;
              b.src = "https://snap.licdn.com/li.lms-analytics/insight.min.js";
              s.parentNode.insertBefore(b, s);})();
          `,
        }}
      />
      <Script
        id="hotjarscript"
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `(function(h,o,t,j,a,r){
          h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
          h._hjSettings={hjid:2263603,hjsv:6};
          a=o.getElementsByTagName('head')[0];
          r=o.createElement('script');r.async=1;
          r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
          a.appendChild(r);
      })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');`,
        }}
      />
    </>
  );
};

export default ProdScripts;
