import React, { useState, useRef } from "react";
import dynamic from "next/dynamic";

import Avatar from "@lc/ui/components/Avatar";
import Badge from "@lc/ui/components/Badge";
import MenuIcon from "@lc/ui/icons/Menu";
import Link from "@lc/ui/components/Link";
import Button from "@lc/ui/components/Button";
import Text from "@lc/ui/components/Text";
import Image from "@lc/ui/components/Image";
import SearchInput from "@lc/ui/template/SearchInput";
import { useProvider } from "@lc/lib/Provider";

const Menu = dynamic(() => import("../Menu"));

import styles from "./header.module.scss";

const Header = ({
  hideSearch = false,
}: {
  hideSearch: boolean;
}): React.ReactElement => {
  const menuRef = useRef<HTMLElement>(null);
  const [open, setOpen] = useState(false);

  const {
    badges: { hasNotification, hasMessages },
    user: { isLogged, userData },
  } = useProvider();

  const hasAvatar = !!userData?.user?.avatar;

  return (
    <header className={styles.wrapper}>
      <div className={styles.maxWidth}>
        <Link href="/">
          <>
            <div className={styles.logo}>
              <Image
                src="images/logo.png"
                alt="Logo letclinic"
                objectFit="contain"
                width={200}
                height={100}
              />
            </div>
            <div className={styles.logoMobile}>
              <Image
                src="images/logoOnly.png"
                alt="Logo letclinic"
                objectFit="contain"
                width={35}
                height={50}
              />
            </div>
          </>
        </Link>
        <div className={styles.searchInput}>
          {!hideSearch ? <SearchInput /> : null}
        </div>
        <Badge
          show={hasNotification || hasMessages}
          position={{ top: "12px", left: "20px" }}
        >
          <div
            ref={menuRef as React.RefObject<HTMLDivElement>}
            className={styles.loginBtn}
          >
            <Button
              size="md"
              color="secondary"
              onClick={(): void => {
                setOpen(!open);
              }}
              fullWidth
            >
              <div className={styles.innerButton}>
                <MenuIcon fontSize={24} />
                {isLogged ? (
                  <Avatar>
                    {hasAvatar ? (
                      <Image
                        src={userData?.user?.avatar}
                        width={36}
                        height={36}
                        alt="Imagem do perfil do usuário"
                      />
                    ) : (
                      <Text element="span" noMargin color="white">
                        <b>
                          {userData?.user?.first_name.charAt(0)}
                        </b>
                      </Text>
                    )}
                  </Avatar>
                ) : (
                  <Text element="span" noMargin color="white">
                    <b>Entrar</b>
                  </Text>
                )}
              </div>
            </Button>
          </div>
        </Badge>
        <Menu
          hasNotification={hasNotification}
          hasMessages={hasMessages}
          anchorRef={menuRef}
          open={open}
          desktop
          onClose={(): void => setOpen(false)}
        />
      </div>
    </header>
  );
};

export default Header;
