import { IconBaseProps } from "react-icons";
import { IoLogoWhatsapp } from "react-icons/io";
import { SiWhatsapp } from "react-icons/si";

import {
  IoLogoFacebook,
  IoLogoInstagram,
  IoLogoLinkedin,
  IoLogoTwitter,
  IoLogoYoutube,
} from "react-icons/io5";

export const FacebookIcon = (props: IconBaseProps): React.ReactElement => {
  return <IoLogoFacebook {...props} />;
};

export const InstagramIcon = (props: IconBaseProps): React.ReactElement => {
  return <IoLogoInstagram {...props} />;
};

export const LinkedinIcon = (props: IconBaseProps): React.ReactElement => {
  return <IoLogoLinkedin {...props} />;
};

export const TwitterIcon = (props: IconBaseProps): React.ReactElement => {
  return <IoLogoTwitter {...props} />;
};

export const YoutubeIcon = (props: IconBaseProps): React.ReactElement => {
  return <IoLogoYoutube {...props} />;
};

export const WhatsappIcon = (props: IconBaseProps): React.ReactElement => {
  return <IoLogoWhatsapp {...props} />;
};
export const WhatsappIcon2 = (props: IconBaseProps): React.ReactElement => {
  return <SiWhatsapp {...props} />;
};
