import React from "react";
import classNames from "classnames";

import Text from "@lc/ui/components/Text";

import styles from "./howworks.module.scss";

const HowLCWorks = ({ footer = false }): React.ReactElement => (
  <div className={classNames(styles.howLCWorks, { [styles.naked]: footer })}>
    <div className={styles.maxWidth}>
      <Text styleAs="body2" noMargin styleAsOnMd="h2">
        {footer ? (
          <>
            <b>Como funciona a Let Clinic</b>
          </>
        ) : (
          <>
            Como funciona a <b>Let Clinic</b>
          </>
        )}
      </Text>
      {!footer ? <br /> : null}
      <Text styleAs="body2" noMargin styleAsOnMd="body1">
        Bem-vindo(a) à comunidade de compartilhamento de espaços da área da
        saúde. A nossa plataforma conecta de forma simples, rápida e segura os
        proprietários de consultórios com os profissionais da saúde que desejam
        alugar estes espaços. Mais rentabilidade, flexibilidade e segurança
        através de uma curadoria rigorosa.
      </Text>
      <br />
      <Text styleAs="body2" noMargin styleAsOnMd="body1">
        <b>Quer alugar um consultório?</b>
      </Text>
      <Text styleAs="body2" noMargin styleAsOnMd="body1">
        Atenda seus pacientes de onde quiser pagando apenas por períodos
        utilizados. É possível escolher seu consultório a partir da cidade,
        bairro, preços e até mesmo por períodos curtos e específicos. É mais
        flexibilidade para você e mais comodidade para seus pacientes.
      </Text>
      <br />
      <Text styleAs="body2" noMargin styleAsOnMd="body1">
        <b>Quer anunciar um consultório?</b>
      </Text>
      <Text styleAs="body2" noMargin styleAsOnMd="body1">
        Com a Let Clinic, você pode disponibilizar seu consultório 7 dias por
        semana divididos em até 3 períodos de 4h por dia. Além de seguro, você
        tem total autonomia para definição de regras, datas, horários e preços.
        É uma ótima maneira de tornar rentável seu espaço ocioso.
      </Text>
    </div>
  </div>
);

export default HowLCWorks;
