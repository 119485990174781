import React from "react";
import ImageNext, { ImageProps } from "next/image";

const Image = (props: ImageProps): React.ReactElement => {
  const { src } = props;

  if (
    src &&
    (src.toString().startsWith("http://") ||
    src.toString().startsWith("https://"))
  ) {
    return (
      <ImageNext
        loader={({ src }): string => src}
        {...props}
        src={src}
        unoptimized
      />
    );
  }
  return <ImageNext {...props} src={`/${src}`} />;
};

export default Image;
