import { ReactElement } from "react";
import classNames from "classnames";
import styles from "./badge.module.scss";

interface Props extends React.HTMLAttributes<HTMLElement> {
  show: boolean;
  children: ReactElement;
  position: {
    top?: string;
    left?: string;
    right?: string;
    bottom?: string;
  };
}

const Badge = (props: Props): ReactElement => {
  const { children, show, style, position, ...rest } = props;
  const { top, left, right, bottom } = position;

  return (
    <div className={styles.badge}>
      <span
        className={classNames({
          [styles.hasNotification]: show,
        })}
        style={{
          ...style,
          top,
          left,
          right,
          bottom,
        }}
        {...rest}
      />
      {children}
    </div>
  );
};

export default Badge;
