import { useCallback, useRef } from "react";

const useLockBodyScroll = (): {
  lockBodyScroll: Function;
  unlockBodyScroll: Function;
} => {
  const lockBodyScroll = useCallback(() => {
    document.body.style.overflow = "hidden";
  }, []);

  const unlockBodyScroll = useCallback(() => {
    document.body.style.overflow = "visible";
  }, []);

  return { lockBodyScroll, unlockBodyScroll };
};

export default useLockBodyScroll;
