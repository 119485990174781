import { useCallback, useState, useEffect } from "react";

import api from "@lc/lib/api";
import useUserInfo from "./useUser";

const useBadges = () => {
  const [hasNotification, setHasNotification] = useState(false);
  const [hasMessages, setHasMessages] = useState(false);
  const { isLogged } = useUserInfo();

  const checkNotifications = useCallback(async () => {
    if (!isLogged) return;
    const { data, hasError } = await api.hasNotifications();
    if (!hasError && data.notifications) {
      setHasNotification(true);
    } else {
      setHasNotification(false);
    }

    if (!hasError && data.messages) {
      setHasMessages(true);
    } else {
      setHasMessages(false);
    }
  }, [isLogged]);

  useEffect(() => {
    checkNotifications();

    const timer = setInterval(() => {
      checkNotifications();
    }, 1200000);

    return () => clearInterval(timer);
  }, [checkNotifications]);

  return { hasNotification, hasMessages };
};

export default useBadges;
