import React from "react";
import Script from "next/script";

import appConfig from "@lc/lib/config";

const DevScripts = (): React.ReactElement => (
  <>
    <Script
      id="gatrackingscript"
      strategy="afterInteractive"
      dangerouslySetInnerHTML={{
        __html: ` window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());
        
          gtag('config', '${appConfig.GA_TRACKING_ID}');`,
      }}
    />
    <Script
      strategy="afterInteractive"
      src={`https://www.googletagmanager.com/gtag/js?id=${appConfig.GA_TRACKING_ID}`}
    />
  </>
);

export default DevScripts;
