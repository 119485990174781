import { createContext, useContext } from "react";
import useBadges from "./hooks/useBadges";
import useUser, { useUserProps } from "./hooks/useUser";

type badgesProps = {
  hasNotification: boolean;
  hasMessages: boolean;
};

type LCProvider = {
  badges: badgesProps;
  user: useUserProps;
};

export const LCContext = createContext<LCProvider>({
  badges: { hasNotification: false, hasMessages: false },
  user: {
    isLogged: false,
    userData: undefined,
    updateUserData: () => {},
    saveUserData: () => {},
    cleanUserData: () => {},
  },
});

const Provider = ({
  children,
}: {
  children: React.ReactElement | React.ReactElement[];
}) => {
  const { hasNotification, hasMessages } = useBadges();
  const user = useUser();

  return (
    <LCContext.Provider
      value={{
        badges: { hasNotification, hasMessages },
        user,
      }}
    >
      {children}
    </LCContext.Provider>
  );
};

export const useProvider = () => {
  const { badges, user } = useContext(LCContext);
  return { badges, user };
};

export const useUserInfo = () => {
  const { user } = useContext(LCContext);
  return { ...user };
};

export default Provider;
