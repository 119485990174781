import { memo } from "react";

import {
  WhatsappIcon,
  FacebookIcon,
  InstagramIcon,
  LinkedinIcon,
  TwitterIcon,
  YoutubeIcon,
} from "@lc/ui/icons/socialIcons";

import routes from "@lc/lib/routes";
import useModal from "@lc/lib/hooks/useModal";
import useDrawer from "@lc/lib/hooks/useDrawer";

import Button from "@lc/ui/components/Button";
import Link from "@lc/ui/components/Link";
import Text from "@lc/ui/components/Text";

import HowLCWorks from "@lc/ui/template/home/components/HowLCWorks";
import Helper from "@lc/ui/template/Helper";

import styles from "./footer.module.scss";

const Footer = () => {
  const { openModal, Modal } = useModal({
    content: () => <HowLCWorks footer />,
    backButton: true,
  });
  const { openHelper, Drawer } = useDrawer({
    direction: "RTL",
    content: <Helper />,
  });

  return (
    <>
      {Modal}
      {Drawer}
      <footer className={styles.wrapper}>
        <div className={styles.innerWrapper}>
          <div className={styles.ourLinks}>
            <div>
              <Text styleAsOnSm="h3">
                <b>Let Clinic</b>
              </Text>
              <ul>
                <li className={styles.aboutUs}>
                  <Button noPadding naked asLink onClick={() => openModal()}>
                    <Text
                      styleAs="body2"
                      styleAsOnSm="h4"
                      element="span"
                      noMargin
                    >
                      Sobre nós
                    </Text>
                  </Button>
                </li>
                <li>
                  <Button noPadding naked asLink onClick={openHelper}>
                    <Text
                      styleAs="body2"
                      styleAsOnSm="h4"
                      element="span"
                      noMargin
                    >
                      Ajuda
                    </Text>
                  </Button>
                </li>
                <li>
                  <Link
                    naked
                    href={routes.public.terms.termsOfService}
                    textProps={{ styleAs: "body2", styleAsOnSm: "h4" }}
                  >
                    Políticas de uso
                  </Link>
                </li>
                <li>
                  <Link
                    naked
                    href={routes.public.mailto}
                    textProps={{ styleAs: "body2", styleAsOnSm: "h4" }}
                  >
                    Suporte
                  </Link>
                </li>
                <li>
                  <Link
                    naked
                    href={routes.public.mailto}
                    textProps={{ styleAs: "body2", styleAsOnSm: "h4" }}
                  >
                    Trabalhe conosco
                  </Link>
                </li>
                <li>
                  <Link
                    naked
                    href={routes.public.blog}
                    textProps={{ styleAs: "body2", styleAsOnSm: "h4" }}
                  >
                    Blog
                  </Link>
                </li>
              </ul>
            </div>
            <div>
              <Text styleAsOnSm="h3">
                <b>Nossa rede</b>
              </Text>

              <ul>
                <li>
                  <Link
                    naked
                    href={routes.public.search}
                    textProps={{ styleAs: "body2", styleAsOnSm: "h4" }}
                  >
                    Quero Alugar
                  </Link>
                </li>
                <li>
                  <Link
                    naked
                    href={routes.public.hosterHome}
                    textProps={{ styleAs: "body2", styleAsOnSm: "h4" }}
                  >
                    Quero Anunciar
                  </Link>
                </li>
                <li>
                  <Link
                    naked
                    href={routes.public.login}
                    textProps={{ styleAs: "body2", styleAsOnSm: "h4" }}
                  >
                    Cadastre-se
                  </Link>
                </li>
              </ul>
            </div>
          </div>
          <ul className={styles.social}>
            <li>
              <Link
                title="Contato do whatsapp"
                naked
                href={routes.public.whatsapp}
                textProps={{ styleAs: "body2" }}
                newWindow
              >
                <WhatsappIcon />
              </Link>
            </li>
            <li>
              <Link
                title="Página do facebook"
                naked
                href={routes.public.social.fb}
                textProps={{ styleAs: "body2" }}
                newWindow
              >
                <FacebookIcon />
              </Link>
            </li>
            <li>
              <Link
                title="Conta do instagram"
                naked
                href={routes.public.social.ig}
                textProps={{ styleAs: "body2" }}
                newWindow
              >
                <InstagramIcon />
              </Link>
            </li>
            <li>
              <Link
                title="Página do Linkedin"
                naked
                href={routes.public.social.in}
                textProps={{ styleAs: "body2" }}
                newWindow
              >
                <LinkedinIcon />
              </Link>
            </li>
            {/* <li>
              <Link
                title="Página do Twitter"
                naked
                href={routes.public.social.twitter}
                textProps={{ styleAs: "body2" }}
                newWindow
              >
                <TwitterIcon />
              </Link>
            </li> */}
            <li>
              <Link
                title="Canal do youtube"
                naked
                href={routes.public.social.yt}
                textProps={{ styleAs: "body2" }}
                newWindow
              >
                <YoutubeIcon />
              </Link>
            </li>
          </ul>
        </div>
      </footer>
    </>
  );
};

export default memo(Footer);
